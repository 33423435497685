@import 'assets/scss/settings';
@import '~bootstrap/scss/bootstrap';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~bootstrap-icons/font/bootstrap-icons.scss';

/**
 * Global styles
 */
a.disabled {
	pointer-events: none;
	cursor: default;
}

body {
	height: auto;
	min-height: 100vh;
	position: relative;
}

h1 {
	font-family: 'Lora', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
		'Noto Color Emoji';
}

h1,
h2,
h3,
h4 {
	strong {
		color: theme-color('primary');
	}
}

label {
	font-size: 0.75rem;
	width: 100%;
}

.btn-primary {
	color: $white;
}

.btn-outline-primary:not(:disabled):not(.disabled) {
	color: theme-color('primary');

	&:hover {
		color: $white;
	}
}

.btn-outline-white {
	@include button-outline-variant(#fff, gray('200'));
}

.btn-soft {
	background-color: theme-color('soft-blue');
	color: theme-color('blue');
}

.btn-link-danger {
	@extend .btn-link;

	color: theme-color('danger');

	&:hover {
		color: theme-color('danger');
	}
}

.btn-link-white {
	@extend .btn-link;

	color: $white;

	&:hover {
		color: $white;
	}
}

.btn-link-secondary {
	@extend .btn-link;

	color: theme-color('secondary');

	&:hover {
		color: theme-color('secondary');
	}
}

.selectable {
	user-select: auto;
}

.breadcrumb-item {
	color: $white;
}

.page {
	margin-top: 2rem;
	padding-bottom: 160px; // height of footer
}

::-moz-selection {
	/* Code for Firefox */
	color: $white;
	background: theme-color('primary');
}

::selection {
	color: $white;
	background: theme-color('primary');
}

/**
 * Scroll bars
 */
/* The emerging W3C standard
   that is currently Firefox-only */
.scrollbar {
	scrollbar-width: thin;
	scrollbar-color: $gray-400 none;
}

/* Works on Chrome/Edge/Safari */
.scrollbar::-webkit-scrollbar {
	height: 0.4rem;
	width: 0.4rem;
}
.scrollbar::-webkit-scrollbar-track {
	background: none;
}
.scrollbar::-webkit-scrollbar-thumb {
	background-color: $gray-400;
	border-radius: 20px;
}

/**
 * Modals
 */
.modal-header {
	background-color: theme-color('primary');
	color: $white;
	padding: 1rem 1.5rem;

	.modal-title {
		margin: 0;
		font-size: 1rem;
		font-weight: $font-weight-normal;
	}

	.close {
		position: fixed;
		top: 1rem;
		right: 1rem;
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		background-color: $white;
		color: $black;
		margin: 0;
		opacity: 0.4rem;
		padding: 0;
	}

	.btn-link-white {
		padding: 0;
	}
}

.modal-body {
	p {
		font-size: 0.8rem;

		small {
			color: gray('400');
		}
	}

	&.edit {
		.row {
			margin-bottom: 0;
		}
	}

	.container-fluid {
		padding-left: 0;
		padding-right: 0;
	}

	.row {
		margin-bottom: 2rem;
	}

	.modal-body-title {
		font-size: 1.125rem;

		p {
			font-size: 1.125rem;
		}
	}

	.modal-body-header {
		font-size: 0.875rem;
		color: gray('600');
		font-weight: $font-weight-bold;
		margin-bottom: 0.25rem;
	}
}

//  .modal-footer {
//    padding: 0 1.5rem 1rem 1.5rem;
//  }

/**
  * Make fontawesome work on Windows?
  */
.bi,
.bis {
	font-weight: 900 !important;
}

.ql-editor {
	strong {
		font-weight: $font-weight-bold;
	}
}

/*
 * Cards
 */

.card {
	box-shadow: $box-shadow;

	&.option {
		cursor: pointer;

		&:hover {
			background-color: rgba(0, 0, 0, 0.025);
		}

		&.success {
			box-shadow: 0 0 0 2px theme-color(primary);
		}

		&.biil {
			box-shadow: 0 0 0 2px $danger;
		}
	}
}

.card.option {
	cursor: pointer;
	display: inline-block;
	font-weight: $font-weight-normal;
	margin-right: 2%;
	position: relative;
	text-align: left;
	width: 32%;

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		background-color: gray('100');
	}

	&.success {
		box-shadow: 0 0 0 2px theme-color('success');
	}

	&.biil {
		box-shadow: 0 0 0 2px theme-color('danger');
	}

	.description {
		font-size: 0.625rem;

		&.bi {
			font-size: 0.75rem;
			position: absolute;
			right: 0.5rem;
			top: 50%;
			transform: translateY(-50%);
		}

		&.bi-circle {
			color: gray('200');
		}

		&.bi-x-circle {
			color: theme-color('danger');

			&.success {
				color: theme-color('success');
			}
		}

		&.bi-check-circle {
			color: theme-color('success');
		}
	}
}

/**
* ng-select
*/
.ng-select.custom {
	color: gray('200');
	font-size: 0.75rem;
}

.ng-invalid.ng-touched,
.ng-invalid.ng-dirty {
	border-color: theme-color('danger');
}

.description-icon {
	color: gray('600');
}

.hand {
	cursor: pointer;
}

.header {
	font-weight: $font-weight-bold;
}

.identifier {
	font-size: 0.625rem;
	color: gray('400');
}

.badge {
	font-size: 0.625rem;
	border-radius: 0.125rem;
	padding: 0.25rem 0.5rem;

	i {
		margin-right: 0.25rem;
	}

	&.learn {
		background-color: theme-color('orange');
	}

	&.confirm {
		background-color: theme-color('blue');
		color: $white;
	}

	&.light-blue {
		background-color: theme-color('soft-blue');
		color: theme-color('blue');
	}
}

.avatar {
	border-width: 0;
	border-radius: 50%;
	color: $white;
	text-align: center;
	background-color: gray('400');
	display: inline-block;
	font-weight: $font-weight-bold;
}

/**
 * Product detail pages
 */
.product-side-bar {
	flex: 0 0 15rem;
	max-width: 15rem;
	min-height: calc(100vh - 88px);
	padding-bottom: 15rem;
	position: relative;
	transition: max-width 0.5s ease;
	user-select: none;

	h5 {
		font-size: $font-size-base;
	}

	p {
		font-size: 0.8rem;
	}

	.hide-collapsed {
		transition-delay: display 0.5s;
	}

	&.collapsed {
		max-width: 4rem;

		.hide-collapsed {
			display: none;
		}

		.no-logo {
			display: block;
		}

		.toggle-btn {
			transform: rotate(180deg);
		}

		#productDropdown {
			i {
				margin-left: -0.1rem;
			}
		}
	}

	&:hover {
		.toggle-btn {
			display: block;
		}
	}

	.toggle-btn {
		display: none;
		position: absolute;
		bottom: 1rem;
		z-index: 100;
		background-color: $white;
		border-radius: 50%;
		transition: transform 1s ease;

		&:hover {
			display: block;
			background-color: theme-color('primary');
		}
	}

	&.left {
		border-right: 1px solid gray('200');

		.toggle-btn {
			right: -1rem;
		}
	}

	&.right {
		border-left: 1px solid gray('200');
		padding-top: 1.5rem;

		p {
			margin-bottom: 1.5rem;

			&.header {
				margin-bottom: 0.875rem;
			}
		}

		.toggle-btn {
			left: -1rem;
		}
	}

	.alert {
		font-size: 0.8rem;
		margin-left: 0;
		margin-right: 0;
		margin-top: 1.5rem;

		
			color: #1b646f;
				background-color: #d1ecf1;
			// border-color: theme-color('blue');
		

		&.alert-info {
			color: theme-color('primary');
				background-color: theme-color('soft-blue');
			
			// border-color: #1b646f;
		}

		&.bottom {
			position: absolute;
			bottom: 15px;
			left: 50%;
			margin-left: calc(-50% + 15px);
			margin-top: 0;
			width: calc(100% - 30px);
		}
	}
}

.wizard {
	&.alert {
		font-size: 0.8rem;
		margin-left: 0;
		margin-right: 0;
		margin-top: 1rem;

		color: theme-color('blue');
		background-color: theme-color('soft-blue');
		border-color: theme-color('blue');
	}
}

.risk-scores {
	.most-risky {
		color: theme-color('orange');
	}

	.risky {
		color: theme-color('primary');
	}

	.confirm {
		color: theme-color('success');
	}
}

.product-content {
	padding-left: 2rem;
	padding-top: 1.5rem;
	padding-right: 2rem;
	width: calc(100% - 15rem);

	h4 {
		padding: 6px 0;
	}

	& .row {
		margin-bottom: 3rem;
	}

	.header-info {
		margin-bottom: 0;
		color: gray('600');

		& i {
			margin-right: 0.5rem;
		}
	}
}

.decision-info-container {
	.row {
		margin-bottom: 3rem;
	}
}

.header-dropdown,
#headerDropdown,
#sprintDropdown,
#sortDropdown {
	h4 {
		font-weight: $font-weight-bold;
		display: inline-block;
		margin-bottom: 0;
	}

	&.dropdown-toggle::after {
		display: none;
	}

	i {
		margin-left: 0.625rem;
		color: theme-color(primary);
	}
}

.dropup,
.dropright,
.dropdown,
.dropleft {
	user-select: none;
}

.range-container {
	position: relative;
	padding-bottom: 0.75rem;

	label {
		margin-bottom: 0;
	}

	p {
		position: absolute;
		bottom: 0;
		color: gray('400');
		font-size: 0.75rem;

		&.left {
			left: 15px;
		}

		&.right {
			right: 15px;
			text-align: right;
		}
	}
}

.drag-cursor {
	cursor: move; /* fallback if grab cursor is unsupported */
	// cursor: grab;
	// cursor: -moz-grab;
	// cursor: -webkit-grab;
}

.placeholderblock {
	width: 100%;
	background-color: gray('100');
	border: 1px dashed gray('200');
	height: 2rem;
}

.settings-content {
	padding: 2rem 0 2rem 0.5rem;
}

/**
 * Classes needed for exporting pitch to powerpoint
 */
.ppt {
	width: 1090px;

	.col-md-2 {
		flex: 0 0 16.6%;
		max-width: 16.6%;
	}

	.col-md-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}

	.col-lg-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}

	.col-md-4 {
		flex: 0 0 33.3%;
		max-width: 33.3%;
	}

	.col-md-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}

	.col-md-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}

	.col-md-10 {
		flex: 0 0 83.3%;
		max-width: 83.3%;
	}

	.col-md.pitch {
		flex: 0 0 33.3%;
		max-width: 33.3%;
	}

	.card {
		box-shadow: none;
		border: 1px solid gray('200');

		.btn {
			display: none;
		}

		.icon {
			&.problem {
				background-image: url('/assets/img/canvas/problem.svg');
				background-color: transparent !important;
				mask: none;
			}

			&.solution {
				background-image: url('assets/img/canvas/solution.svg');
				background-color: transparent !important;
				mask: none;
			}

			&.revenue {
				background-image: url('assets/img/canvas/revenue.svg');
				background-color: transparent !important;
				mask: none;
			}

			&.scale {
				background-image: url('/assets/img/canvas/scale.svg');
				background-color: transparent !important;
				mask: none;
			}
		}
	}
}

/**
 * Quil editor
 */
.ql-toolbar.ql-snow {
	border-top-left-radius: $border-radius;
	border-top-right-radius: $border-radius;
	padding: 4px !important;

	button {
		width: 1.5rem !important;
		height: 1.25rem !important;
	}
}
.ql-container.ql-snow {
	border-bottom-left-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
}

.editor-data {
	img {
		width: 100%;
	}
}

/**
 * Headway widget
 */
#HW_badge_cont {
	position: absolute !important;
	top: 0;
	right: -0.25rem;
	height: 100%;
	width: 100%;

	#HW_badge {
		left: unset;
		right: 0;
		top: 1.5rem;
	}

	.HW_softHidden {
		background: transparent;
	}
	.HW_visible {
		background: theme-color('primary');
	}
}
