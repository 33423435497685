$theme-colors: (
	'primary': #137795,
	'blue': #137795,
	'soft-blue': #eef6fc,
	'orange': #e8702b,
	'success': #1b9988,
	'danger': #da4d2b,
	'warning': #f4c34e,
	'knowledge': #4cd964,
	'happiness': #f3a116,
);
$black: #202020;
$body-color: #202020;
$white: #fff;
// $info: #007bff;

$box-shadow: 0 0 4px 1px rgba(202, 202, 202, 0.5);

$gray-100: #f8f8f8;
$gray-200: #e5e5ea;
$gray-400: #c7c7cc;
$gray-600: #8e8e93;

$light: $gray-200;

$font-size-base: 0.9rem;
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * 0.875;

$font-family-base: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
	'Noto Color Emoji';
$font-weight-light: 100;
$font-weight-normal: 400;
$font-weight-bold: 700;

$headings-font-weight: $font-weight-bold;

$h1-font-size: $font-size-base * 1.5;
$h2-font-size: $font-size-base * 1.25;
$h3-font-size: $font-size-base * 1.3;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: 1rem; //$font-size-base;

$paragraph-margin-bottom: 0;

$navbar-padding-y: 0;
$navbar-light-color: $black;
$navbar-light-hover-color: $black;
$navbar-light-active-color: $black;

$breadcrumb-bg: map-get($theme-colors, 'primary');
$breadcrumb-divider-color: $white;
$breadcrumb-active-color: $white;
$breadcrumb-border-radius: 0;

$breadcrumb-margin-bottom: 0;

$btn-font-weight: $font-weight-bold;
// $btn-font-size:					.75rem;

// $custom-range-thumb-bg:			map-get($theme-colors, 'primary');

// $input-font-size:					.75rem;
$input-margin-bottom: 1.5rem;
// Also used for input and textarea
$input-btn-font-size: 0.75rem;

$border-width: 1px;
$border-radius: 0.25rem;

$card-spacer-y: 0.5rem;
$card-spacer-x: 0.5rem;
$card-border-radius: 0.25rem;
$card-border-color: none;
$card-border-width: 0;
$card-cap-color: $black;
$card-color: $black;
$card-bg: $white;

// $modal-header-padding-y: 		1rem;
// $modal-header-padding-x: 		1rem;
$modal-backdrop-opacity: 0.8;
$modal-content-border-width: 0;
$modal-inner-padding: 1rem 1.5rem;
$modal-footer-margin-between: 1rem;

$modal-xl: 1140px;
$modal-lg: 800px;
$modal-md: 560px;
$modal-sm: 412px;

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1440px,
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1440px,
);

// Include Bootstrap vars
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
